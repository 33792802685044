<template>
  <div
    :style="{ background: loginScreenStyle.background }"
    class="session-wrapper login-screen">
    <v-container
      grid-list-xl
      class="column-layout justify-center full-height">
      <div class="text-xs-center">
        <div class="session-table-cell row-layout-center">
          <div
            v-if="onboardingStep === 0"
            class="session-content login-content">
            <div class="login-logo">
              <img
                :src="require('../../assets/images/logo.svg')"
                alt="Drive Me">
            </div>
            <div>
              <h2 class="mb-3 white-18 text-upper pb-4">Welkom bij de club</h2>
              <p class="fs-14 white-14 mb-1">Laten we starten met het aanmaken</p>
              <p class="fs-14 white-14 pb-5">van jouw persoonlijke account</p>
              <div class="pt-5 pb-5 mb-5">
                <v-btn
                  id="onboarding-step-1"
                  large
                  dark
                  color="pink"
                  block
                  @click="onboardingStep = 1"><span class="white-14">account aanmaken</span></v-btn>
              </div>
            </div>
          </div>
          <div
            v-if="onboardingStep === 1"
            class="session-content login-content">
            <h2 class="mb-4 pb-4 white-18 text-upper">Verzin een wachtwoord</h2>
            <v-form
              ref="resetForm"
              v-model="valid"
              class="mb-4 mt-4">
              <v-text-field
                v-model="newPassword"
                :rules="passwordRules"
                type="password"
                dark
                label="Wachtwoord"
                required
                @keyup.enter="submit"
              />
              <v-text-field
                v-model="newPasswordRepeat"
                :rules="passwordRules"
                type="password"
                dark
                label="Herhaal wachtwoord"
                required
                @keyup.enter="submit"
              />

              <v-btn
                id="onboarding-submit"
                :disabled="!valid"
                :loading="submitLoader"
                class="mt-2"
                large
                dark
                color="pink"
                block
                @click="submit"><div class="white-14">Wachtwoord instellen</div></v-btn>
            </v-form>
          </div>
          <div
            v-if="onboardingStep === 2"
            class="session-content login-content">
            <h2 class="mb-3 white-18 text-upper">Controleer jouw profiel</h2>
            <img
              :src="userInfo.photo != null ? userInfo.photo : require('../../assets/images/profile-male@2x.png')"
              width="130"
              style="border-radius: 50%;"
              alt="Profile">
            <h2 class="mb-3 pt-2 white-14">
              <div class="pb-2">{{ userInfo.phone }}</div>
              <div class="pb-2">{{ userInfo.email }}</div>
              <div class="pb-2">{{ userInfo.address }}</div>
              <div>{{ userInfo.description }}</div>
            </h2>
            <div class="pt-5">
              <v-btn
                id="onboarding-step-next"
                class="mt-2"
                large
                dark
                color="pink"
                block
                @click="onboardingStep++"><div class="white-14">Dit klopt</div></v-btn>
            </div>
            <div class="white-12 mt-2">
              <div> Klopt het niet?</div>
              <div>Stuur een e-mail naar <a href="mailto:support@driveme.nl">support@driveme.nl</a></div>
            </div>
          </div>
          <div
            v-if="onboardingStep === 3"
            class="session-content login-content">
            <h2 class="mb-3 white-18 text-upper">Dat was het!</h2>
            <h2 class="mb-3 pt-2 white-16">Je kunt nu je eerste rit aanmaken</h2>
            <div class="pt-5">
              <v-btn
                id="onboarding-step-3"
                class="mt-2"
                large
                dark
                color="pink"
                block
                @click="sendCheckedAt()"><div class="white-14">OK</div></v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SessionSliderWidget from 'Components/Widgets/SessionSlider'
import Request from '../../helpers/request'
// import { storeToken } from '../../helpers/tokenHelper'

export default {
  components: {
    SessionSliderWidget
  },
  mixins: [Request],
  data () {
    return {
      email: '',
      valid: true,
      onboardingStep: 0,
      newPassword: '',
      newPasswordRepeat: '',
      loaders: {
        passengerData: false,
        userData: false,
        recovery: false,
        meData: false
      },
      passwordRules: [v => !!v || 'Is verplicht'],
      userInfo: {},
      userRole: ''
    }
  },
  computed: {
    loginScreenStyle () {
      return {
        background: `linear-gradient(to bottom, ${this.$store.getters.colorGuide.login.gradient.from} 40vh, ${this.$store.getters.colorGuide.login.gradient.to} 100%), url(${this.$store.getters.colorGuide.login.image}) ${this.$vuetify.breakpoint.xsOnly ? this.$store.getters.colorGuide.login.imageParams : this.$store.getters.colorGuide.login.imageParamsDesktop}`
      }
    },
    submitLoader () {
      return this.loaders.passengerData || this.loaders.userData || this.loaders.recovery || this.loaders.meData
    }
  },
  mounted () {
  },
  methods: {
    sendCheckedAt () {
      this.request('POST', '/user-log/click-ok-onboarding', {}, ({ data }) => {
        this.userRole === 'passenger' ? this.$router.push('/create-ride') : this.$router.push('/rides')
      })
    },
    resetpassword () {
      if (this.$refs.resetForm.validate()) {
        let body = {
          data: {
            email: this.email
          }
        }
        this.requestNoAuth('POST', '/emailing/user-reset-password', body, ({ data }) => {
          this.linkSent = true

          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Een link is toegestuurd om je wachtwoord te herstellen'
          })
        })
      }
    },
    submit () {
      if (this.$refs.resetForm.validate()) {
        let body = {
          data: {
            token: this.$route.query.token,
            password: this.newPassword,
            passwordRepeat: this.newPasswordRepeat
          }
        }

        this.requestNoAuth('POST', '/recover', body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Wachtwoord ingesteld'
          })

          // storeToken(data)

          this.constructRequests()

          this.request('GET', '/me', {}, ({ data }) => {
            this.$store.dispatch('loginUser', { data })
            this.$store.dispatch('setUser', data)

            this.authorizeUser(data)

            this.userRole = (this.$store.getters.getEnv.envVersion.siteVersion === 'ROLE_DRIVER' ? 'driver' : 'passenger')

            let getPassengerData = () => {
              return new Promise((resolve) => {
                this.request('GET', `/${this.userRole}s/${this.$store.getters.getUser[this.userRole].id}`, {}, ({ data }) => {
                  this.userInfo.description = data.description
                  this.userInfo.address = data.address
                  this.userInfo.photo = data.photo != null ? data.photo.url : null
                  this.$forceUpdate()
                  resolve()
                }, (loader) => {
                  this.loaders.passengerData = loader
                })
              })
            }

            let getUserData = () => {
              return new Promise((resolve) => {
                this.request('GET', `/users/${this.$store.getters.getUser.id}`, {}, ({ data }) => {
                  this.userInfo.email = data.email
                  this.userInfo.phone = data.phone
                  this.$forceUpdate()
                  resolve()
                }, (loader) => {
                  this.loaders.userData = loader
                })
              })
            }

            Promise.all([getPassengerData(), getUserData()]).then(() => {
              this.onboardingStep++
            })
          }, (loader) => { this.loaders.meData = loader })
        }, (loader) => { this.loaders.recovery = loader })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-content {
      width: 90%;
      max-width: 350px;
}
.login-logo {
    margin-bottom: 10%;
    margin-top: 10px;
}
</style>
